import { useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { redirect } from 'redux-first-router';
import { useTracking } from 'react-tracking';
import DocumentHeader from '@/components/DocumentHeader';
import SkyscraperBackground from '@/components/SkyscraperBackground';
import Break from '@/components/Break';
import type { Method } from '@/components/AuthenticationSelect';
import { AuthenticationSelect } from '@/components/AuthenticationSelect';
import { ROUTE_WELCOME, ROUTE_USER_REGISTER } from '@/constants/routes';
import { useMount } from '@simplywallst/ui-core';

import { WelcomeWrapper, Heading } from './styled';
import { clearAllCookies } from '@/utilities/cookies';
import { getActionFromPath, useLocation } from '@/hooks/useLocation';

export const WelcomePage = () => {
  const { Track, trackEvent } = useTracking({
    type: 'track',
    page: 'Welcome',
    subject: 'page',
  });

  const queryClient = useQueryClient();
  useMount(() => {
    trackEvent({
      action: 'landed',
    });
    queryClient.clear();
    clearAllCookies();
  });

  const location = useLocation();

  const r = location.query?.r;

  const type = useSelector<{ location: { type: string } }, Method>((state) => {
    switch (state.location.type) {
      case ROUTE_USER_REGISTER:
        return 'register';
      case ROUTE_WELCOME:
      default:
        return 'login';
    }
  }, shallowEqual);
  const dispatch = useDispatch();

  return (
    <Track>
      <SkyscraperBackground>
        <DocumentHeader title={'Welcome to Simply Wall St'} titleSuffix="" />
        <WelcomeWrapper>
          <Heading>
            Continue to Simply Wall St stock
            <Break smUp /> analysis tool
          </Heading>
          <AuthenticationSelect
            postVerifyRedirectTo={r}
            onChangeType={(type) => {
              if (type === 'login') {
                dispatch(redirect({ type: ROUTE_WELCOME }));
              }
              if (type === 'register') {
                dispatch(
                  redirect({ type: ROUTE_USER_REGISTER, query: location.query })
                );
              }
            }}
            current={type}
            onRegisterSuccess={() => {
              trackEvent({
                modifier: 'registration',
                action: 'success',
              });
            }}
            onLoginSuccess={() => {
              const action = getActionFromPath(r);
              if (typeof action !== 'undefined') {
                dispatch(
                  redirect({
                    // typing is complaining when passing the entire action object
                    type: action.type,
                    payload: action.payload,
                    // https://github.com/faceyspacey/redux-first-router/blob/master/src/flow-types.js#L173
                    // https://github.com/faceyspacey/redux-first-router/blob/master/src/pure-utils/pathToAction.js
                    // @ts-expect-error action.meta is an object
                    query: action.query ?? action.meta?.query ?? {},
                  })
                );
              }
            }}
          />
        </WelcomeWrapper>
      </SkyscraperBackground>
    </Track>
  );
};
