import styled from 'styled-components';
import { media } from '@simplywallst/ui-core';

export interface BreakProps {
  xsDown?: boolean;
  smDown?: boolean;
  mdDown?: boolean;
  lgDown?: boolean;
  smUp?: boolean;
  mdUp?: boolean;
  lgUp?: boolean;
  xlUp?: boolean;
  xsOnly?: boolean;
  smOnly?: boolean;
  mdOnly?: boolean;
  lgOnly?: boolean;
  xlOnly?: boolean;
}

const display = `display: inline !important;`;
const displayNone = `display: none !important;`;

const Break = styled('br')<BreakProps>`
  ${({ xsOnly, smOnly, mdOnly, lgOnly, xlOnly }) =>
    xsOnly || smOnly || mdOnly || lgOnly || xlOnly ? displayNone : ``}
  ${({
    smUp,
    mdUp,
    lgUp,
    xlUp,
    xsDown,
    smDown,
    mdDown,
    lgDown,
    xsOnly,
    smOnly,
    mdOnly,
    lgOnly,
    xlOnly,
  }) => {
    if (smUp) return media.xsDown`${displayNone}`;
    if (mdUp) return media.smDown`${displayNone}`;
    if (lgUp) return media.mdDown`${displayNone}`;
    if (xlUp) return media.lgDown`${displayNone}`;
    if (xsDown) return media.smUp`${displayNone}`;
    if (smDown) return media.mdUp`${displayNone}`;
    if (mdDown) return media.lgUp`${displayNone}`;
    if (lgDown) return media.xlUp`${displayNone}`;
    if (xsOnly) return media.xsOnly`${display}`;
    if (smOnly) return media.smOnly`${display}`;
    if (mdOnly) return media.mdOnly`${display}`;
    if (lgOnly) return media.lgOnly`${display}`;
    if (xlOnly) return media.xlOnly`${display}`;
  }};
`;

export default Break;
